<template>
  <b-container fluid>
    <b-row>
      <b-col style="padding: 0">
        <nav_bar />
        <div class="bg"></div>
      </b-col>
    </b-row>

    <b-row style="margin-top: 10%">
      <b-col cols="6">
        <div class="left-box">
          <div class="title">
            <div class="judul">
              <span>pertumbuhan pengguna</span>
            </div>
            <div class="status">
              <span class="aktif">88 aktif</span>
              <span class="unaktif">88 tidak aktif</span>
            </div>
          </div>
          <linear />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="right-box">
          <donut />
        </div>
      </b-col>
    </b-row>

    <b-row style="padding-top: 4%" align-h="between">
      <b-col style="display: flex; justify-content: flex-end">
        <div style="width: 50%">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>

    <b-row style="padding-top: 3%; height: 550px">
      <b-col>
        <b-table
          :items="listKAP"
          :fields="heads"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          thead-tr-class="tinggi"
          stacked="md"
          show-empty
          hover
          small
          style="
            text-align: center;
            font-family: 'nunito', sans-serif;
            background-color: #fff;
          "
          @filtered="onFiltered"
        >
          <template #cell(name)="row">
            {{ row.value.first }} {{ row.value.last }}
          </template>

          <template #cell(actions)="row">
            <b-button
              @click="openBlock(row.item, 'block')"
              class="mr-1"
              style="
                background-color: #e0544e;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                border-radius: 50px;
                border: none;
                margin: 0 4%;
              "
              v-if="row.item.deletedAt === null"
            >
              Blokir
            </b-button>
            <b-button
              @click="openBlock(row.item, 'open')"
              class="mr-1"
              style="
                background-color: #20bd95;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                border-radius: 50px;
                border: none;
                margin: 0 4%;
              "
              v-else
            >
              Aktivasi
            </b-button>
            <b-button
              @click="openModal(row.item)"
              style="
                background-color: #327ff2;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                border-radius: 50px;
                border: none;
                margin: 0 2%;
              "
            >
              Atur Limitasi
            </b-button>
            <b-button
              style="
                background-color: #327ff2;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                border-radius: 50px;
                border: none;
              "
              @click="openProfile(row.item)"
            >
              Profil KAP
            </b-button>
            <b-button
              style="
                background-color: #e0544e;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                border-radius: 50px;
                border: none;
                margin: 0 4%;
              "
              @click="openDelete(row.item)"
              >Hapus</b-button
            >
          </template>
        </b-table>

        <!---------------------------------- MODALS ---------------------------------->
        <b-modal hide-footer hide-header centered id="limit" size="lg">
          <div
            class="contentss"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding: 1% 2%;
            "
          >
            <div class="title" style="width: 100%; text-align: start">
              <span
                class="title"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 28px;
                  font-weight: 700;
                  text-transform: capitalize;
                "
                >Atur Limitasi</span
              >
            </div>

            <div class="form" style="width: 95%; margin-top: 4%">
              <label
                for="example-input"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 20px;
                  font-weight: 300;
                  margin-top: 2%;
                "
                >Pilih Tanggal Expired</label
              >
              <b-form-input
                id="example-input"
                type="date"
                placeholder="DD-MM-YYYY"
                autocomplete="off"
                size="lg"
                style="border-radius: 10px"
              ></b-form-input>

              <b-form-group
                label="Limit Jumlah User"
                label-for="input-formatter"
                class="mb-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 20px;
                  font-weight: 300;
                  margin-top: 2%;
                "
              >
                <b-form-input
                  v-model="maxUsers"
                  id="input-formatter"
                  placeholder="Limit Jumlah User"
                  style="border-radius: 10px"
                  size="lg"
                  type="number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Limit Jumlah Perusahaan"
                label-for="input-formatter"
                class="mb-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 20px;
                  font-weight: 300;
                  margin-top: 2%;
                "
              >
                <b-form-input
                  v-model="maxClient"
                  id="input-formatter"
                  placeholder="Limit Jumlah Perusahaan"
                  style="border-radius: 10px"
                  size="lg"
                  type="number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Limit Project"
                label-for="input-formatter"
                class="mb-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 20px;
                  font-weight: 300;
                  margin-top: 2%;
                "
              >
                <b-form-input
                  v-model="maxProject"
                  id="input-formatter"
                  placeholder="Limit Project"
                  style="border-radius: 10px"
                  size="lg"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div
            class="wrapper"
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              padding: 0 2%;
            "
          >
            <div
              class="button-wrapper"
              style="display: flex; width: 95%; justify-content: flex-end"
            >
              <b-button
                @click="doAdd"
                style="
                  background-color: #327ff2;
                  color: #fff;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  width: 25%;
                  border: none;
                  margin-top: 20px;
                  height: 40px;
                "
                v-if="loadingAdd == false"
                >Simpan</b-button
              >
              <b-button
                disabled
                v-else
                style="
                  background-color: #327ff2;
                  color: #fff;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  width: 25%;
                  border: none;
                  margin-top: 20px;
                  height: 40px;
                "
                ><white-spinner
              /></b-button>
            </div>
          </div>
        </b-modal>

        <b-modal hide-header hide-footer centered id="confirmation">
          <div
            class="title"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 26px;
              font-weight: 700;
              text-transform: capitalize;
              text-align: center;
              margin-top: 4%;
            "
          >
            <span v-if="status === 'block'"
              >Apakah Anda ingin memblokir <br />{{ blokir }}</span
            >
            <span v-else>Apakah Anda ingin aktivasi <br />{{ blokir }}</span>
          </div>

          <div
            v-if="!password"
            class="button-wrapper"
            style="
              margin-top: 6%;
              margin-bottom: 2%;
              width: 100%;
              display: flex;
              justify-content: space-evenly;
            "
          >
            <b-button
              @click="close"
              style="
                background-color: #fff;
                color: #327ff2;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                border: 1px solid #327ff2;
                text-transform: capitalize;
              "
              >Batal</b-button
            >
            <b-button
              v-if="status === 'block'"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                text-transform: capitalize;
                background-color: #e0544e;
                border: 1px solid #e0544e;
              "
              @click="password = true"
              >Ya</b-button
            >
            <b-button
              v-else
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                text-transform: capitalize;
                background-color: #20bd95;
                border: 1px solid #20bd95;
              "
              @click="password = true"
              >Ya</b-button
            >
          </div>
          <div v-else>
            <b-form-group style="margin-top: 4%">
              <template v-slot:label> Masukan Password </template>
              <b-form-input v-model="pass" type="password"></b-form-input>
            </b-form-group>
            <div
              style="width: 100%; display: flex; justify-content: space-around"
            >
              <b-button
                style="
                  background-color: #fff;
                  color: #327ff2;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  width: 25%;
                  border: 1px solid #327ff2;
                  text-transform: capitalize;
                "
                @click="password = false"
                >Batal</b-button
              >
              <b-button
                v-if="status === 'block'"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  width: 25%;
                  text-transform: capitalize;
                  background-color: #e0544e;
                  border: 1px solid #e0544e;
                "
                @click="block"
                :disabled="loading"
                >{{ pesan }}</b-button
              >
              <b-button
                v-else
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  width: 25%;
                  text-transform: capitalize;
                  background-color: #20bd95;
                  border: 1px solid #20bd95;
                "
                @click="block"
                :disabled="loading"
                >{{ pesan2 }}</b-button
              >
            </div>
          </div>
        </b-modal>

        <b-modal hide-header hide-footer centered id="delete">
          <div
            class="title"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 26px;
              font-weight: 700;
              text-transform: capitalize;
              text-align: center;
              margin-top: 4%;
            "
          >
            <span>Apakah Anda ingin menghapus <br />{{ deleteName }}</span>
          </div>

          <div
            v-if="!password"
            class="button-wrapper"
            style="
              margin-top: 6%;
              margin-bottom: 2%;
              width: 100%;
              display: flex;
              justify-content: space-evenly;
            "
          >
            <b-button
              @click="closeDelete"
              style="
                background-color: #fff;
                color: #327ff2;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                border: 1px solid #327ff2;
                text-transform: capitalize;
              "
              >Batal</b-button
            >
            <b-button
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                text-transform: capitalize;
                background-color: #e0544e;
                border: 1px solid #e0544e;
              "
              @click="password = true"
              >Ya</b-button
            >
          </div>
          <div v-else>
            <b-form-group style="margin-top: 4%">
              <template v-slot:label> Masukan Password </template>
              <b-form-input v-model="pass" type="password"></b-form-input>
            </b-form-group>
            <div
              style="width: 100%; display: flex; justify-content: space-around"
            >
              <b-button
                style="
                  background-color: #fff;
                  color: #327ff2;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  width: 25%;
                  border: 1px solid #327ff2;
                  text-transform: capitalize;
                "
                @click="password = false"
                >Batal</b-button
              >
              <b-button
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  width: 25%;
                  text-transform: capitalize;
                  background-color: #e0544e;
                  border: 1px solid #e0544e;
                "
                @click="deleteKAP"
                :disabled="loading"
                >{{ pesanDelete }}</b-button
              >
            </div>
          </div>
        </b-modal>

        <b-modal centered id="profile" :title="profile.namaKAP" hide-footer>
          <div style="font-size: 18px">
            <div>Nomor Ijin : {{ profile.noIjin }}</div>
            <div>Alamat : {{ profile.alamatKAP }}</div>
            <div>Nomor Telepon : {{ profile.telfon }}</div>
            <div>Alamat Email : {{ profile.emailKAP }}</div>
            <div>
              Alamat Website :
              <a :href="'https://' + profile.website" target="_blank">{{
                profile.website
              }}</a>
            </div>
          </div>
        </b-modal>
      </b-col>
    </b-row>

    <b-alert
      style="
        position: fixed;
        top: 90%;
        right: 0;
        left: 0;
        bottom: 4%;
        width: 90%;
        margin: auto;
        z-index: 10;
      "
      :show="show"
      dismissible
      :variant="variant"
      >{{ msg }}</b-alert
    >

    <b-row style="margin-top: 2%">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="listKAP.length"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import nav_bar from "../../components/nav_bar.vue";
import axios from "axios";
import { ip_server } from "@/ip_config";
import WhiteSpinner from "../../components/loader/white-spinner.vue";
import moment from "moment";
import Linear from "../../components/chart/linear.vue";
import Donut from "../../components/chart/donut.vue";

export default {
  components: { nav_bar, WhiteSpinner, Linear, Donut },
  data() {
    return {
      listKAP: [],
      loading: false,
      loadingAdd: false,
      maxUsers: "",
      maxProject: "",
      maxClient: "",
      idModal: "",
      blokir: "",
      blokirId: "",
      password: false,
      pass: "",
      loading: false,
      pesan: "Blokir",
      pesan2: "Aktivasi",
      variant: "success",
      show: false,
      status: "",
      msg: "",
      heads: [
        {
          key: "namaKAP",
          label: "Nama KAP",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "createdAt",
          label: "Bergabung",
        },
        {
          key: "maxUsers",
          label: "Daftar AP",
        },
        {
          key: "maxClient",
          label: "Jumlah Perusahaan",
        },
        {
          key: "maxProject",
          label: "Jumlah Project",
        },
        // {
        //   key: "referal",
        //   label: "Kode Referal",
        // },
        {
          key: "actions",
          label: "Edit",
        },
      ],
      sortDirection: "asc",
      currentPage: 1,
      perPage: 10,
      filter: null,
      profile: "",
      deleteId: "",
      deleteName: "",
      pesanDelete: "HAPUS",
    };
  },

  mounted() {
    this.getKAP();
  },

  methods: {
    openProfile(item) {
      this.profile = item;
      this.$root.$emit("bv::show::modal", "profile");
    },
    openDelete(item) {
      this.deleteId = item.id;
      this.deleteName = item.namaKAP;
      this.$root.$emit("bv::show::modal", "delete");
    },
    closeDelete() {
      this.$root.$emit("bv::hide::modal", "delete");
    },
    async deleteKAP() {
      let vm = this;
      vm.loading = true;
      vm.pesanDelete = "Mohon Tunggu";

      let deleting = await axios.post(
        ip_server + "KAP/destroyKAP",
        {
          KAPId: vm.deleteId,
          password: vm.pass,
        },
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );

      if (deleting.data.message == "sukses") {
        vm.variant = "success";
        vm.msg = `BERHASIL MENGHAPUS KAP ${vm.deleteName}`;
        this.$root.$emit("bv::hide::modal", "delete");
        vm.pass = "";
        vm.deleteId = "";
        vm.pesanDelete = "Hapus";
        vm.show = true;
        setTimeout(() => {
          vm.show = false;
        }, 4000);
        vm.getKAP();
      } else {
        vm.variant = "danger";
        vm.msg = deleting.data.message;
        this.$root.$emit("bv::hide::modal", "delete");
        vm.pass = "";
        vm.deleteId = "";
        vm.pesanDelete = "Hapus";
        vm.show = true;
        setTimeout(() => {
          vm.show = false;
        }, 4000);
        vm.getKAP();
      }
    },
    async block() {
      let vm = this;
      vm.loading = true;

      if (vm.status === "block") {
        vm.pesan = "Mohon Tunggu";
        let blocking = await axios.post(
          ip_server + "KAP/delete",
          {
            password: vm.pass,
            id: vm.blokirId,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (blocking.data.message == "sukses") {
          vm.getKAP();
          vm.loading = false;
          vm.pesan = "Blokir";
          this.$root.$emit("bv::hide::modal", "confirmation");
          vm.msg = `BERHASIL MEMBLOKIR KAP ${vm.blokir}`;
          vm.variant = "success";
          vm.pass = "";
          vm.password = false;
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 4000);
        } else {
          vm.getKAP();
          vm.loading = false;
          vm.pesan = "Blokir";
          this.$root.$emit("bv::hide::modal", "confirmation");
          vm.msg = blocking.data.message;
          vm.variant = "danger";
          vm.pass = "";
          vm.password = false;
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 4000);
        }
      } else {
        vm.pesan2 = "Mohon Tunggu";
        let blocking = await axios.post(
          ip_server + "KAP/restoreKAP",
          {
            password: vm.pass,
            KAPId: vm.blokirId,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (blocking.data.message == "sukses") {
          vm.getKAP();
          vm.loading = false;
          vm.pesan2 = "Aktivasi";
          vm.pass = "";
          vm.password = false;
          this.$root.$emit("bv::hide::modal", "confirmation");
          vm.msg = `BERHASIL AKTIVASI KAP ${vm.blokir}`;
          vm.variant = "success";
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 4000);
        } else {
          vm.getKAP();
          vm.loading = false;
          vm.pesan = "Blokir";
          this.$root.$emit("bv::hide::modal", "confirmation");
          vm.msg = blocking.data.message;
          vm.variant = "Aktivasi";
          vm.pass = "";
          vm.password = false;
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 4000);
        }
      }
    },
    close() {
      this.$root.$emit("bv::hide::modal", "confirmation");
    },
    openModal(row) {
      let vm = this;
      vm.idModal = row.id;
      this.$root.$emit("bv::show::modal", "limit");
    },

    onFiltered(filteredItems) {
      let vm = this;
      vm.totalRows = filteredItems.length;
      vm.currentPage = 1;
    },

    openBlock(item, status) {
      let vm = this;
      vm.blokir = item.namaKAP;
      vm.blokirId = item.id;
      vm.status = status;
      this.$root.$emit("bv::show::modal", "confirmation");
    },

    async getKAP() {
      let vm = this;
      vm.loading = true;
      let x = localStorage.getItem("token");
      moment.locale("id");

      await axios
        .get(ip_server + "KAP/allKAP", {
          headers: {
            token: x,
          },
        })
        .then((res) => {
          vm.listKAP = res.data;

          vm.listKAP.forEach((el, id) => {
            vm.listKAP[id].createdAt = moment(el.createdAt).format("L");
          });
          vm.loading = false;
        });
    },

    async doAdd() {
      let vm = this;
      vm.loadingAdd = true;
      let x = localStorage.getItem("token");

      await axios.post(
        ip_server + "KAP/updateLimit",
        {
          id: vm.idModal,
          maxUsers: vm.maxUsers,
          maxClient: vm.maxClient,
          maxProject: vm.maxProject,
        },
        {
          headers: {
            token: x,
          },
        }
      );
      vm.loadingAdd = false;
      vm.maxUsers = "";
      vm.maxClient = "";
      vm.maxProject = "";
      this.$root.$emit("bv::hide::modal", "limit");
      this.getKAP();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "purple": #5c4af5,
  "gray-dark": #b5bac3,
  "green": #20bd95,
);

.bg {
  background-color: map-get($color, "blue");
  height: 60vh;
  width: 100%;
  position: fixed;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;

  .tinggi {
    background-color: red !important;
  }
}

.left-box {
  background-color: #fff !important;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  height: 550px;
  .title {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 28px;
    text-transform: capitalize;
    padding: 1% 0;
    margin-bottom: 1%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .status {
      width: 30%;
      display: flex;
      justify-content: space-evenly;
      font-size: 16px;
      font-weight: 300;
      margin-right: 6%;
      .aktif {
        color: map-get($color, "green");
        text-transform: capitalize;
      }
      .unaktif {
        text-transform: capitalize;
        color: map-get($color, "red");
      }
    }
  }
}

.right-box {
  background-color: #fff !important;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  height: 550px;
}
</style>