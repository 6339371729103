<template>
  <div class="wrapper">
    <div class="title">
      <span>Project on Progress</span>
    </div>
    <div class="donut">
      <div class="angka">
        <div class="persen">{{ val }}</div>
        <div class="upah">{{ valLabels }}</div>
      </div>
      <apexchart
        type="donut"
        :series="series"
        :width="412"
        :options="chartOptions"
      ></apexchart>
    </div>
    <div class="legend">
      <div class="top">
        <span style="color: #6adbbe">Just Started : 100</span>
        <span style="color: #5c4af5">Mid Proccess: 40</span>
      </div>
      <div class="bot">
        <span style="color: #f4b366">Almost Deadline : 20</span>
        <span style="color: #e0544e">Over Deadline : 10</span>
      </div>
      <div class="bot">
        <span style="color: #696a6d">Canceled : 5</span>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexcharts: VueApexCharts },

  data() {
    return {
      series: [100, 40, 20, 10, 5],
      val: "",
      valLabels: "",
      chartOptions: {
        dataLabels: {
          style: {
            colors: ["#061737"],
          },
          dropShadow: {
            enabled: false,
          },
        },
        labels: [
          "Just Started",
          "Middle Process",
          "Almost Deadline",
          "Over Deadline",
          "Canceled",
        ],
        colors: ["#6ADBBE", "#5C4AF5", "#F4B366", "#E0544E", "#696a6d"],
        chart: {
          id: "charts",
          type: "donut",
          events: {
            dataPointSelection: (a, b, config) => {
              let vm = this;
              if (config.dataPointIndex == 0) {
                vm.val = vm.series[0];
                vm.valLabels = vm.chartOptions.labels[0];
              } else if (config.dataPointIndex == 1) {
                vm.val = vm.series[1];
                vm.valLabels = vm.chartOptions.labels[1];
              } else if (config.dataPointIndex == 2) {
                vm.val = vm.series[2];
                vm.valLabels = vm.chartOptions.labels[2];
              } else if (config.dataPointIndex == 3) {
                vm.val = vm.series[3];
                vm.valLabels = vm.chartOptions.labels[3];
              } else if (config.dataPointIndex == 4) {
                vm.val = vm.series[4];
                vm.valLabels = vm.chartOptions.labels[4];
              }
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },

  mounted() {
    let vm = this;
    vm.val = vm.series[2];
    vm.valLabels = vm.chartOptions.labels[2];
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .title {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 28px;
  }
  .donut {
    font-family: "nunito", sans-serif;
    color: map-get($color, "color");
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    .angka {
      position: absolute;
      .persen {
        font-size: 40px;
        font-weight: 700;
        text-align: center;
      }
      .upah {
        font-size: 14px;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    width: 80%;
    font-family: "nunito", sans-serif;
    font-weight: 600;
    margin-top: 4%;
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0.3% 0;
      font-size: 18px;
    }
    .bot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 18px;
      padding: 0.3% 0;
    }
  }
}
</style>