<template>
  <div class="wrapper-linear">
    <div class="line">
      <apexcharts
        :height="400"
        type="line"
        :series="series"
        :options="chartOptions"
      >
      </apexcharts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: { apexcharts: VueApexCharts },
  data() {
    return {
      series: [
        {
          name: "sales",
          data: [32, 47, 68, 90, 57, 85, 140, 23, 67, 120, 69, 45, 17],
        },
      ],
      // xaxis: {
      //   categories: ['January', 'February', 'March', 'April']
      // },
      chartOptions: {
        stroke: {
          curve: "straight",
        },
        markers: {
          size: 7,
        },
        dataLabels: {},
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
    };
  },
};
</script>

<style lang='scss' scoped>
.wrapper-linear {
  width: 100%;
  .line {
    width: 100%;
  }
}
</style>